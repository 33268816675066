jQuery(document).ready(function($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$("a.level").each(function(i,o){
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai'+'lt'+'o:'+c).html(c);
	});

});

$(document).ready(function () {
	$(window).scroll(function () {
		if (getWindowScrolled() > 200) {
			$('.fixed').addClass('fixedShadow');
		}
		else {
			$('.fixed').removeClass('fixedShadow');
		}
	});

	var $map = document.querySelector('#gmap');

	if ($map) {
		var lat = 46.220006;
		var long = 18.553912;

		var lat1 = $('.sz_lat').text();
		var long1 = $('.sz_lng').text();

		var lat2 = $('.p_lat').text();
		var long2 = $('.p_lng').text();

		map = new GMaps({
			div: '#gmap',
			// lat: lat,
			// lng: long,
			mapTypeControl: false,
			navigationControl: false,
			scrollwheel: false,
			streetViewControl: false,
			draggable: true,
			disableDoubleClickZoom: true,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.LARGE,
				position: google.maps.ControlPosition.LEFT_CENTER
			},
			disableMovement: true,
			panControl: false,
			zoom: 10,
			center: new google.maps.LatLng(46.220006, 18.553912),
			styles: [{featureType: "poi", elementType: "geometry", stylers: [{visibility: "off"}]}, {
				featureType: "poi",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {
				featureType: "administrative",
				elementType: "geometry.fill",
				stylers: [{visibility: "off"}]
			//}, {featureType: "road", elementType: "geometry", stylers: [{visibility: "off"}]
			}, {
				featureType: "road",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {
				featureType: "road.local",
				elementType: "all",
				stylers: [{visibility: "on"}]
			}, {
				featureType: "road.highway.controlled_access",
				elementType: "all",
				stylers: [{visibility: "on"}, {color: "#cccccc"}, {weight: .7}]
			}, {
				featureType: "road.highway.controlled_access",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {featureType: "water", elementType: "geometry.fill", stylers: [{color: "#c7deed"}]}, {
				featureType: "water",
				elementType: "geometry.stroke",
				stylers: [{color: "#7eb9e0"}]
			}, {
				featureType: "administrative.province",
				elementType: "labels.text.fill",
				stylers: [{color: "#949494"}]
			}, {
				featureType: "administrative.province",
				elementType: "geometry.stroke",
				stylers: [{weight: .65}]
			}, {
				featureType: "administrative.country",
				elementType: "labels.text.fill",
				stylers: [{color: "#949494"}]
			}, {
				featureType: "administrative.locality",
				elementType: "labels.text.fill",
				stylers: [{color: "#949494"}]
			}, {
				featureType: "administrative.country",
				elementType: "geometry.stroke",
				stylers: [{color: "#7d7d7d"}, {weight: .9}]
			}, {
				featureType: "landscape.natural",
				elementType: "geometry.fill",
				stylers: [{color: "#f7f7f7"}]
			}, {
				featureType: "landscape.man_made",
				elementType: "geometry.fill",
				stylers: [{color: "#f7f7f7"}]
			}, {featureType: "transit", elementType: "all", stylers: [{visibility: "off"}]}, {
				featureType: "poi",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {
				featureType: "landscape",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {
				featureType: "administrative.neighborhood",
				elementType: "labels",
				stylers: [{visibility: "off"}]
			}, {featureType: "road.arterial", elementType: "labels.icon", stylers: [{visibility: "off"}]}]

		});

		map.addMarker({
			lat: lat1,
			lng: long1,
			title: $('.sz_varos').text(),
			icon: "images/marker.png",
			infoWindow: {
				content: '<p>' + $('.sz_varos').text() + '</p><p>' + $('.sz_cim').text() + '</p><p>' + $('.sz_telefon').text() + '</p><p>' + $('.sz_email').text() + '</p><p><a target="_blank" href="http://maps.google.com/?q=' + lat1 + ',' + long1 + '">Térképen</a></p>'
			}
		});

		map.addMarker({
			lat: lat2,
			lng: long2,
			title: $('.p_varos').text(),
			icon: "images/marker.png",
			infoWindow: {
				content: '<p>' + $('.p_varos').text() + '</p><p>' + $('.p_cim').text() + '</p><p>' + $('.p_telefon').text() + '</p><p>' + $('.p_email').text() + '</p><p><a target="_blank" href="http://maps.google.com/?q=' + lat2 + ',' + long2 + '">Térképen</a></p>'
			}
		});
	}

	$('.scroll-link').click(function(e) {
		//e.preventDefault();
		var id = this.hash.substr(1);
		goToByScroll(id);
	});

});

function getWindowScrolled() {
	return $(window).scrollTop()
}

function goToByScroll(id){
	$('html,body').animate({ scrollTop: $("#"+id).offset().top }, 1200);
}

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function(e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function(resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('change blur', 'form.auto :input', function(e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function(e){
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function(resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function(cb, resp, $elem) {
		$.each(cb.split(',') || [], function(i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	kosar: function(resp, $elem) {
		if (resp.lista) {
			$('.kosar-lista').replaceWith(resp.lista);
		}
		if (resp.darab) {
			$('.kosar-darab').html(resp.darab);
		}
	}
};